import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import archerhubnews from "../../images/archerhubnews.png"

const Card = styled.div`
  width: 400px;
  @media screen and (max-device-width: 600px) {
    width: 300px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 300px;
  }
  @media screen and (max-device-width: 1024px) and (orientation: landscape) {
    width: 300px;
  }
  @media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    width: 350px;
  }
`
const NewsSection = ({ className }) => {
  const data = useStaticQuery(graphql`
    query NewsSectionQuery {
      allWordpressPost(
        filter: { categories: { elemMatch: { slug: { eq: "news" } } } }
      ) {
        edges {
          node {
            title
            slug
            excerpt
            wordpress_id
            content
            featured_media {
              source_url
              title
            }
          }
        }
      }
    }
  `)
  const posts = data.allWordpressPost.edges
  return (
    <>
      {posts.length > 0 ? (
        <div className={className}>
          <div className="w-full">
            <h1 className="text-gray-800 text-2xl lg:text-4xl font-bold text-center mb-16 hover:text-blue-700">
              <Link to="/news">Archerhub News</Link>
            </h1>
          </div>
          <div className="w-full">
            <div className="flex flex-wrap lg:flex-no-wrap content-center justify-center md:justify-around">
              {posts.slice(0, 3).map(({ node }) => {
                return (
                  <Link key={node.wordpress_id} to={`/news/${node.slug}`}>
                    <Card className="lg:flex-auto mb-6 lg:mb-0">
                      <div className="w-full lg:max-w-sm rounded overflow-hidden shadow-lg">
                        {node.featured_media ? (
                          <div
                            className="h-48 bg-gray-400 bg-cover bg-center bg-no-repeat"
                            style={{
                              backgroundImage: `url(${node.featured_media.source_url})`,
                            }}
                            title={node.featured_media.title}
                          ></div>
                        ) : (
                          <div
                            className="h-48 bg-cover bg-center rounded"
                            style={{
                              backgroundImage: `url(${archerhubnews})`,
                            }}
                            title="Default Image"
                          />
                        )}
                        <div className="px-6 py-4 bg-white">
                          <h3 className="font-semibold text-xl text-gray-800"  dangerouslySetInnerHTML={{__html:node.title}}>
                          </h3>
                          <p className="text-md text-gray-700">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: node.excerpt,
                              }}
                            ></span>
                          </p>
                        </div>
                        {/* <div className="px-6 py-4 bg-white">
                          <span className="inline-block text-gray-600">
                            {Utils.longDate(node.createdAt)}
                          </span>
                        </div> */}
                      </div>
                    </Card>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

NewsSection.propTypes = {
  className: PropTypes.string,
}

NewsSection.defaultProps = {
  className: null,
}

export default NewsSection
