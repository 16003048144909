import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../shared/image"

const TrustedPartners = ({ className }) => {
  const data = useStaticQuery(graphql`
    query TrustedPartnersQuery {
      hood: file(relativePath: { eq: "hood-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 261, maxHeight: 148) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      acme: file(relativePath: { eq: "acme-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      petsmart: file(relativePath: { eq: "petsmart-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 397, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anheuser: file(relativePath: { eq: "anheuser-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 272, maxHeight: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hanover: file(relativePath: { eq: "hanover-foods.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1780, maxHeight: 711) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tyson: file(relativePath: { eq: "tyson-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 289, maxHeight: 111) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hyvee: file(relativePath: { eq: "hyvee-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 289, maxHeight: 109) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boulder: file(relativePath: { eq: "boulder-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, maxHeight: 209) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={className}>
      <div className="flex flex-wrap justify-center">
        <h1 className="text-gray-800 text-2xl lg:text-4xl font-bold mb-16">
          Trusted Partners
        </h1>

        <div className="w-full">
          <div className="flex justify-around">
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.hood.childImageSharp.fluid}
              alt="Archer Hub's Trusted Partner - Hood"
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.acme.childImageSharp.fluid}
              alt="Archerhub's Trusted Partner - ACME Manufacturing Company, Inc."
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.petsmart.childImageSharp.fluid}
              alt="Archer Hub's Trusted Partner - PetSmart"
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.anheuser.childImageSharp.fluid}
              alt="Archerhub's Trusted Partner - Anheuser-Busch"
            />
          </div>
          <div className="flex justify-around items-center">
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.hanover.childImageSharp.fluid}
              alt="Archer Hub's Trusted Partner - Hanover "
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.tyson.childImageSharp.fluid}
              alt="Archerhub's Trusted Partner - Tyson"
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.hyvee.childImageSharp.fluid}
              alt="Archer Hub's Trusted Partner - HyVee"
            />
            <Image
              width="150px"
              mobileWidth="100px"
              tabletWidth="100px"
              fluid={data.boulder.childImageSharp.fluid}
              alt="Archerhub's Trusted Partner - Boulder Organic Foods"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

TrustedPartners.propTypes = {
  className: PropTypes.string,
}

TrustedPartners.defaultProps = {
  className: null,
}

export default TrustedPartners
