import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Card from "../shared/card"
import Image from "../shared/image"
import NewsSection from "./news-section"
import TrustedPartners from "./trusted-partners"
// import BlogSection from "./blog-section"

const Landing = styled.section`
  margin: 0 auto;
  width: 100%;
  padding: 0px 1.0875rem 1.45rem;
`

const Visibility = styled.div`
  @media screen and (max-device-width: 1600px) {
    margin-top: 10px;
  }
`

const Reliability = styled.div`
  @media screen and (max-device-width: 1600px) {
    margin-top: 10px;
  }
`

const Recovery = styled.div`
  @media screen and (max-device-width: 1600px) {
    margin-top: 10px;
  }
`

const LandingContent = ({ className }) => {
  const data = useStaticQuery(graphql`
    query LandingContentImageQuery {
      visibilitybg: file(relativePath: { eq: "visibility-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1423, maxHeight: 619) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reliabilitybg: file(relativePath: { eq: "reliability-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1186, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recoverybg: file(relativePath: { eq: "recovery-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1412, maxHeight: 616) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      visibilitymb: file(relativePath: { eq: "visibility.png" }) {
        childImageSharp {
          fluid(maxWidth: 1976, maxHeight: 1204) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reliabilitymb: file(relativePath: { eq: "reliability.png" }) {
        childImageSharp {
          fluid(maxWidth: 2160, maxHeight: 1728) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recoverymb: file(relativePath: { eq: "recovery-eyes-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1694, maxHeight: 1124) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Landing className={className}>
      <div className="w-full">
        <Visibility className="flex flex-wrap items-center lg:relative">
          <div className="w-full lg:hidden">
            <Image
              fluid={data.visibilitymb.childImageSharp.fluid}
              alt="24/7 Visibility into your shipments"
            />
          </div>
          <Card className="max-w-sm bg-white rounded-lg overflow-hidden shadow-lg max-w-full lg:max-w-sm lg:absolute lg:z-10 lg:ml-48">
            <div className="px-8 py-6">
              <div className="font-semibold text-gray-800 text-xl mb-2">
                Visibility
              </div>
              <p className="text-gray-700 text-lg">
                Knowing where your shipments are is important. Real time
                visibility 24/7 gives you peace of mind, anytime.
              </p>
            </div>
          </Card>
          <div className="hidden lg:block">
            <Image
              width="1200px"
              tabletWidth="1000px"
              fluid={data.visibilitybg.childImageSharp.fluid}
              alt="24/7 Visibility into your shipments"
            />
          </div>
        </Visibility>
      </div>

      <div className="w-full">
        <Reliability className="flex flex-wrap items-center lg:relative">
          <div className="w-full lg:hidden">
            <Image
              fluid={data.reliabilitymb.childImageSharp.fluid}
              alt="AI-enabled technology helps reduce the amount of truck breakdowns"
            />
          </div>
          <Card className="max-w-full lg:max-w-sm bg-white rounded-lg overflow-hidden shadow-lg lg:absolute lg:z-10 lg:right-0 lg:mr-64">
            <div className="px-8 py-6">
              <div className="font-semibold text-gray-800 text-xl mb-2">
                Reliability
              </div>
              <p className="text-gray-700 text-lg">
                Truck breakdowns can wreak havoc on your production schedules.
                Ship with the right carrier for each shipment, analyzed and
                updated by our Artificial Intelligence.{" "}
                <Link to="/freight-recovery">
                  <span className="text-blue-500 hover:underline">
                    Learn More →
                  </span>
                </Link>
              </p>
            </div>
          </Card>
          <div className="hidden lg:block">
            <Image
              width="1100px"
              tabletWidth="1000px"
              fluid={data.reliabilitybg.childImageSharp.fluid}
              alt="AI-enabled technology helps reduce the amount of truck breakdowns"
            />
          </div>
        </Reliability>
      </div>
      <div className="w-full">
        <Recovery className="flex flex-wrap items-center lg:relative">
          <div className="w-full lg:hidden">
            <Image
              fluid={data.recoverymb.childImageSharp.fluid}
              alt="Shipment recovery plan"
            />
          </div>
          <Card className="max-w-full lg:max-w-sm bg-white rounded-lg overflow-hidden shadow-lg lg:absolute lg:z-10 lg:left-0 lg:ml-48">
            <div className="px-8 py-6">
              <div className="font-semibold text-gray-800 text-xl mb-2">
                Recovery Plan
              </div>
              <p className="text-gray-700 text-lg">
                In logistics, exception management is the rule. With artificial
                intelligence technology working for you 24/7, rest assured there
                is a backup for the backup.
              </p>
            </div>
          </Card>
          <div className="hidden lg:block">
            <Image
              width="1200px"
              tabletWidth="1000px"
              fluid={data.recoverybg.childImageSharp.fluid}
              alt="Shipment recovery plan"
            />
          </div>
        </Recovery>
        <div className="flex justify-center mt-16">
          <Link to="/signup-shipper">
            <Button
              className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 lg:py-4 px-4 lg:px-8 rounded"
              type="button"
            >
              Get Started Today
            </Button>
          </Link>
        </div>
      </div>
      <TrustedPartners className="w-full mt-16" />
      <NewsSection className="w-full mt-16" />
    </Landing>
  )
}

LandingContent.propTypes = {
  className: PropTypes.string,
}

LandingContent.defaultProps = {
  className: null,
}

export default LandingContent
