import React, { Component } from "react"
import LandingContent from "../components/landing/landing-content"
import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends Component {
  render() {
    const desc =
      "Archerhub is the premier digital marketplace for truckload freight where shippers and drivers are connected through AI-enabled technology."
    return (
      <Layout>
        <SEO title="Freight Marketplace" description={desc} />
        <LandingContent className="container mx-auto flex flex-wrap justify-center" />
      </Layout>
    )
  }
}

export default IndexPage
